import { FC } from "react";
import { Gizmo, RootGizmo, useGizmo } from "flowy-3-core";
import * as S from "./Row.styles";
import renderGizmos from "../../render-gizmos";

type RowProps = {
  gizmo: Gizmo;
};

const Row: FC<RowProps> = ({ gizmo }) => {
  const { binder, config } = useGizmo({ gizmo });
  let gizmos: (Gizmo | RootGizmo)[] = [];

  if (binder) {
    gizmos = binder.getGizmos();
  }

  return (
    <S.Children
      id={`row-${gizmo.getConfig().fid}`}
      align="middle"
      justify={config.ops?.row?.justify || "start"}
    >
      {gizmos.length > 0 && renderGizmos(gizmos)}
    </S.Children>
  );
};

export default Row;
