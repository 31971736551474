import { FC } from "react";
import {
  IGizmo,
  IRootGizmoData,
  useFlowyFoundation,
  TOnSubmit,
  IPlatformCallbacks,
} from "flowy-3-core";
import { Spin } from "antd";
import * as S from "./FlowyFoundation.styles";
import renderGizmos from "../../render-gizmos";

type FlowyFoundationProps = {
  config?: IGizmo;
  /**
   * Parent Gizmo Flowy Id, this will be used when we're working with AnnexForms
   */
  parentRootGizmoFid?: string;
  data?: IRootGizmoData;
  onSubmit?: TOnSubmit;
  platformCallbacks?: IPlatformCallbacks;
  params?: any;
};

const FlowyFoundation: FC<FlowyFoundationProps> = ({
  config,
  parentRootGizmoFid,
  data,
  onSubmit,
  platformCallbacks,
  params,
}) => {
  const { rootGizmo } = useFlowyFoundation({
    config,
    parentRootGizmoFid,
    data,
    onSubmit,
    platformCallbacks,
    params,
  });

  return (
    <S.FlowyFoundation>
      {!rootGizmo && <Spin tip="Cargando..." size="large"><div className="content" /></Spin>}
      {rootGizmo && renderGizmos([rootGizmo])}
    </S.FlowyFoundation>
  );
};

export default FlowyFoundation;
