import { FC, useRef, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./SignatureField.styles";
import { GizmoWrapper } from "../../utils";
import { Alert, Button, Image as ImgAntd } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { getFileFromBase64 } from "../../utils/functions/getFile";
import SignaturePad from 'signature_pad';
import { fileToBase64 } from "../../utils/functions";

type SignatureFieldProps = { gizmo: Gizmo; };

const SignatureField: FC<SignatureFieldProps> = ({ gizmo }) => {

  const { config, features, errors, binder } = useGizmo({ gizmo });
  const [value, setValue] = useState<any>("");
  const sigCanvas = useRef<any>(null);
  const [signaturePad, setSignaturePad] = useState<any>(null);
  const [showSizeWarning, setShowSizeWarning] = useState(false)

  useEffect(() => {
    if (binder) {
      const loadSignature = () => {
        const files = binder.getFiles();
        let canvas: any
        if (features.editable) {
          canvas = sigCanvas.current;
          const signaturePad = new SignaturePad(canvas);
          setSignaturePad(signaturePad);
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;
          setShowSizeWarning(window.innerWidth < 500)
        }

        if (files.length) {
          const file = files[0];
          if (features.editable) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const img: any = new Image();
              img.onload = () => {
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);
              };
              img.src = event?.target?.result;
            };
            reader.readAsDataURL(file);
          } else {
            fileToBase64(file).then((value) => {
              setValue("data:image/png;base64," + value)
            })
          }
        }
      }
      loadSignature()
      window.addEventListener("resize", loadSignature);
      return () => {
        window.removeEventListener("resize", loadSignature);
      }
    };
  }, [binder]);

  useEffect(() => {
    return () => {
      if (signaturePad)
        signaturePad.off();
    };
  }, [signaturePad]);

  const handleClearSignature = () => {
    signaturePad.clear();
    handleSignatureDraw()
  };

  const handleSignatureDraw = async () => {
    if (signaturePad && features.editable) {
      const newSignature = sigCanvas.current.toDataURL("image/png");
      const file = await getFileFromBase64(newSignature, "signature.png", "image/png");
      await binder?.setFiles([file]);
      if (signaturePad.isEmpty()) {
        signaturePad.clear();
        signaturePad.on();
      }
    }
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (value ? (
        <S.ContainerImage>
          <ImgAntd src={value} preview={false} />
        </S.ContainerImage>
      ) : (
        <><i>No se firmó</i><br /><br /></>
      )) : (
        <>
          {showSizeWarning && (
            <Alert
              message="Gire la pantalla para editar la firma con más comodidad!"
              type="warning"
              showIcon
              closable
            />
          )}
          <S.ContainerSignature>
            <S.SignatureField
              ref={sigCanvas}
              onClick={handleSignatureDraw}
            />
          </S.ContainerSignature>
          <Button onClick={handleClearSignature} icon={<ClearOutlined />}>
            Limpiar
          </Button>
        </>
      )}
    </GizmoWrapper >
  );
};

export default SignatureField;