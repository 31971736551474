import { FC, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import { Popconfirm } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import * as S from "./Button.styles";

type ButtonProps = {
  gizmo: Gizmo;
};

const Button: FC<ButtonProps> = ({ gizmo }) => {
  const { config, features, binder } = useGizmo({ gizmo });

  useEffect(() => {
    if (binder) {
      binder.data.subscribe((data: any) => {
        if (data.file && data.filename) {
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(data.file);
          downloadLink.download = data.filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      });
    }
  }, [binder]);

  const handleButtonClick = async () => {
    const config = binder.getConfig();

    if (binder && config.ops?.button?.operation) {
      await binder.dispatchOperation({
        operation: config.ops.button.operation,
      });
    }
  };
  let label: string | undefined = features.label;
  let icon: any = undefined;
  if (config.ops?.button?.displayLabel === false) {
    label = undefined;
  }
  if (config.ops?.button?.icon?.name === "HomeOutlined") {
    icon = <HomeOutlined />;
  }

  return (
    <>
      {config.ops?.button?.confirmation?.title ? (
        <Popconfirm
          title={config.ops?.button?.confirmation?.title}
          description={config.ops?.button?.confirmation?.description}
          onConfirm={handleButtonClick}
        >
          <S.Button
            id={`button-${gizmo.getConfig().fid}`}
            loading={features.processing}
            type="primary"
            icon={icon}
          >
            {label}
          </S.Button>
        </Popconfirm>
      ) : (
        <S.Button
          id={`button-${gizmo.getConfig().fid}`}
          //columns={gizmo.getColumns()}
          loading={features.processing}
          onClick={handleButtonClick}
          type="primary"
        >
          {label}
        </S.Button>
      )}
    </>
  );
};

export default Button;
